import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import ChaloGiveMain from './pages/Home';
import About from './pages/About';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<ChaloGiveMain />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </>
  );
}

export default App;
