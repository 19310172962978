import React, { ReactElement } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/admin.css';
import '../styles/chart.css';
import '../styles/equal-height-columns.css';
import '../styles/newone.css';
import '../styles/stylesheet.css';

interface DonorTeamMemberProps {
    props: {
      name: string;
      position: string;
      quote: string;
      imgSrc: string;
      alt: string;
    };
  }

function DonorTeamMember(props: DonorTeamMemberProps): ReactElement {
    return (
        <>
            <div className="col teams-member">
                <img className="mx-auto d-block" src={props.props.imgSrc} alt= {props.props.alt} />
                <div className="founder mb-4">
                    <div> <span><b>{props.props.name}</b></span> </div>
                    <p className="mb-1">{props.props.position}</p>
                    <p className="founder-quote">{props.props.quote}</p>
                </div>
            </div>
        </>
    )
}

export default DonorTeamMember;