import React, { ReactElement } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/admin.css';
import '../styles/chart.css';
import '../styles/equal-height-columns.css';
import '../styles/newone.css';
import '../styles/stylesheet.css';

function Contributors(): ReactElement {
    return (
        <>
            <section id="contributors" className="row justify-content-center  container-fluid margin-left-0 mr-0 pl-0 pr-0  paddingBottom-64-m paddingTop-100-m">
                <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                    <div className="row scrollClass">
                        <div className="col-12  col-sm-5 col-md-5 col-lg-5 col-xl-5">
                            <div className="row">
                                <div className="col-12">
                                    <h3 className="text-primary text-left"><span className="font-weight-semi-bold">WISH Foundation: COVID Care Centers or Makeshift Hospitals</span></h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li>Isolation of COVID-19 positive patients is a crucial measure for preventing the spread of COVID-19, especially critical since families live together in small spaces.</li>
                                        <li>WISH Foundation will set up COVID-Care Centers (CCC) for appropriate care to COVID-19 patients, with amenities such as oxygen, isolation, proper nutrition and regular vital checks.</li>
                                        <li>Delivery of quality health care services through these CCC will also decrease turmoil among the patients and caregivers while reducing community-spread.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 offset-sm-1 offset-md-1 offset-lg-1 offset-xl-1 offset-0">
                            <section className="cause-Hero position-relative align-items-center d-flex justify-content-center">
                                <div className="zIndex-1">
                                    <div className="backgroundColor-grayMedium position-absolute top-0 left-0 right-0 bottom-0 rounded">
                                        <div className="cause-image rounded bg-image"
                                            style= {{backgroundImage : `url(${require('../../assests/images/covid-19/ambulance.jpeg')})`}}>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mt-5">
                    <div className="row scrollClass">
                        <div
                            className="col-12  col-sm-5 col-md-5 col-lg-5 col-xl-5 order-2 order-sm-1 order-md-1 order-lg-1 order-xl-1">
                            <section className="cause-Hero position-relative align-items-center d-flex justify-content-center">
                                <div className="zIndex-1">
                                    <div className="backgroundColor-grayMedium position-absolute top-0 left-0 right-0 bottom-0 rounded">
                                        <div className="cause-image rounded"
                                            style= {{backgroundImage : `url(${require('../../assests/images/covid-19/women-in-truck.jpg')})`}}></div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div
                            className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 offset-sm-1 offset-md-1 offset-lg-1 offset-xl-1 offset-0 order-1 order-sm-2 order-md-2 order-lg-2 order-xl-2">
                            <div className="row">
                                <div className="col-12">
                                    <h3 className="text-primary text-left"><span className="font-weight-semi-bold">GiveIndia: Direct Cash Transfer to Family of Deceased</span></h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <ul>
                                        <li>Out of the 2,500 (and climbing) daily deaths due to COVID, around 1,000 families are from underprivileged backgrounds, with many of them losing the sole bread-earner.</li>
                                        <li>GiveIndia is working towards supporting each family with a 1-time total cash support of Rs 30,000 ($400), as a direct bank transfer.</li>
                                        <li>The cash support will be sent directly to the bank account of the family of the deceased.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mt-5">
                    <div className="row scrollClass">
                        <div className="col-12  col-sm-5 col-md-5 col-lg-5 col-xl-5">
                            <div className="row">
                                <div className="col-12">
                                    <h3 className="text-primary text-left"><span className="font-weight-semi-bold">Goonj and Jan Sahas: Food Relief and Livelihood Assistance</span></h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p>Continuing the extensive work done last year with marginalised communities, Goonj and Jan Sahas are urgently focused on:</p>
                                    <ul>
                                        <li>Providing essentials like ration, hygiene materials, and health supplies.</li>
                                        <li>Safe transportation and facilitation of direct cash transfers for the stranded and distressed migrants.</li>
                                        <li>Working with vulnerable populations (old age homes, artisans, sex workers, transgender groups, etc.) who need immediate health and economic interventions.</li>
                                    </ul>
                                    <p><strong>We have partnered with EdelGive Foundation</strong>, who along with its grassroots partners have stepped up as primary points of contacts to reach the unreached and are yet again at the
                                        centre of relief and response work in the difficult geographies and vulnerable communities they serve. All funds raised for Goonj and Jan Sahas are
                                        sent directly to the organizations without any platform fees.</p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 offset-sm-1 offset-md-1 offset-lg-1 offset-xl-1 offset-0">
                            <section className="cause-Hero position-relative align-items-center d-flex justify-content-center">
                                <div className="zIndex-1">
                                    <div className="backgroundColor-grayMedium position-absolute top-0 left-0 right-0 bottom-0 rounded">
                                        <div className="cause-image rounded"
                                            style= {{backgroundImage : `url(${require('../../assests/images/covid-19/food-relief.jpeg')})`}}>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="10 p-3 mt-5">
                    <h3 className="text-center"> U.S. tax payers are eligible for tax deduction</h3>
                </div>
            </section>
        </>
    )
}

export default Contributors;