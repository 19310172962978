import React, { ReactElement } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/admin.css';
import '../styles/chart.css';
import '../styles/equal-height-columns.css';
import '../styles/newone.css';
import '../styles/stylesheet.css';

function AboutChaloGive(): ReactElement {
    return (
        <>
            <div className="row justify-content-center container-fluid margin-left-0 mr-0 pl-0 pr-0  paddingBottom-64-m about-why">
                <div className="col-12 paddingTop-100-m pb-5">
                    <h2 className="text-center">
                        <span className="">What is ChaloGive?</span>
                    </h2>
                </div>
                <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 ">
                            <img src={require('../../assests/images/chaloGive/logo-indiaspora.png')} alt='logo-indiaspora' />
                        </div>
                        <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                            <h6 className="mb-4"><strong>NOTE: On April 30, 2021, Indiaspora launched a special ChaloGive campaign to raise funds to
                                help with medical and economic necessities related to the current COVID 19 crisis in India.</strong></h6>
                            <h6 className="mb-4">ChaloGive is an online giving campaign to provide the Indian diaspora living in the US
                                a platform to come together and support a cause that they care about
                                which is usually held around Gandhi Jayanthi in October. Extraordinary circumstances have prompted two special campaigns in 2020 and 2021.</h6>
                            <h6 className="mb-4">ChaloGive is part of <a href="http://www.indiaspora.org">Indiaspora’s</a> philanthropy initiative to use crowdfunding;
                                and, also, engage with the Indian diaspora looking for an opportunity to learn and support some of the best charitable
                                organizations in India and their local community. We are here for all those asking "How can I help?":
                                we believe that each of our contributions matters, and adds up to making us a collective force for good.</h6>
                            <h6 className="mb-4">We want to empower the individual donor and create a movement that inspires social change through the
                                joy of giving. Our goals are lofty but we feel confident in achieving them because at the end of the day, we are
                                betting on people’s inherent goodness and the generosity of the human spirit, and in our minds that is a safe bet.</h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutChaloGive;