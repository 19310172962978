import React from 'react';
import ChalogiveNavbar from "../components/shared/Navbar/Navbar";
import Footer from "../components/shared/footer/Footer";
import Cover from '../components/shared/cover/Cover';
import AboutChaloGive from '../components/about-chalo-give/AboutChaloGive';
import AboutOurGoal from '../components/about-our-goal/AboutOurGoal';

function About() {
    const imageArray: string[] = [
        require('../assests/images/ngo/akanksha/Photo.jpg'),
    ]
    return (
        <>
            <ChalogiveNavbar />

            {/* <!-- coversection or Hero section --> */}

            < Cover imageArray={imageArray} classname="HomepagePhotoSlider-image-about" />

            {/* <!-- Why ChaloGive --> */}

            <AboutChaloGive />

            {/* <!-- Our Goal --> */}

            <AboutOurGoal />

            < Footer />
        </>
    )
}

export default About;