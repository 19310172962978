import React, { ReactElement } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/admin.css';
import '../../styles/chart.css';
import '../../styles/newone.css';
import '../../styles/stylesheet.css';

function Footer(): ReactElement {
    return (
        <>
            <footer>
                <div className="row align-items-center">
                    <div className="col-12 col-sm-4 col-md-4 col-lg-5 col-xl-5 text-center text-xs-center text-lg-left text-xl-left mb-2 mb-sm-0">
                        <div className="row">
                            <div className="col-12">
                                <div className="copy-right row text-center text-xs-center text-lg-left text-xl-left pb-2">
                                    <div className="col">
                                        © 2021 Indiaspora, a 501(c)(3)
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="copy-right row text-center text-xs-center text-lg-left text-xl-left">
                                    <div className="col">
                                        Tax Id: EIN 46-4246368
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-2 col-xl-2 text-center mt-2 mt-lg-0">
                        <ul>
                            <li>
                                <a className="footer-brand mr-auto" href="http://indiaspora.org"><img src={require('../../../assests/images/chaloGive/logo-indiaspora.png')}
                                    alt="Giving Rise" /></a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-5 col-xl-5 mt-2 mt-sm-0 text-center text-xs-center text-lg-right text-xl-right">
                        <div className="row">
                            <div className="col-12">
                                <div className="row justify-content-center footer-contact pr-3 pb-2">
                                    <a id="footer-contact" className='footer-contactorg' href="mailto:contact@indiaspora.org">contact@indiaspora.org</a>
                                </div>
                            </div>
                        </div>
                        {/* <div class="row">
                            <div class="col-12">
                                <div class="row justify-content-center justify-content-lg-end pr-3">
                                    <a id="footer-twitter nav-team" href="">FAQ</a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;