import React, { ReactElement } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/admin.css';
import '../styles/chart.css';
import '../styles/equal-height-columns.css';
import '../styles/newone.css';
import '../styles/stylesheet.css';
import DonorTeamMember from './DonorTeamMember';

interface DonorData {
    [keyName: string]: {
      name: string;
      position: string;
      quote: string;
      imgSrc: string;
      alt: string;
    };
  }

function DonorTestimonials(): ReactElement {
    const donorData: DonorData = {
        reshma: {
            name: "Reshma Kewalramani",
            position: "CEO and President at Vertex",
            quote: `I am heartbroken by the deepening COVID crisis in India. Urgent action is needed to help those suffering. Please join me in giving as generously as you can to support India in her time of need.`,
            imgSrc: require('../../assests/images/covid-19/donors/reshma-kewalramani.jpg'),
            alt: "reshma-kewalramani"
        },
        kamini: {
            name: "Kamini Ramani",
            position: "Vice President Marketing, Mayfield",
            quote: `The enormity of what we hear from India feels unbearable. I hope each of us can find some solace in acting to offer financial support to these greatly affected communities.`,
            imgSrc: require('../../assests/images/covid-19/donors/kamini-ramani.jpg'),
            alt: "kamini-ramani"
        },
        narendra: {
            name: "Narendra Mulani",
            position: "Investor and Entrepreneur",
            quote: `The need is critical and the time is now. Let's seize the opportunity
            to prove ourselves as high-achievers in collective giving. Let us take pride in rising to the
            occasion and translate our compassion into generous action through ChaloGive.`,
            imgSrc: require('../../assests/images/covid-19/donors/narendra-mulani.jpg'),
            alt: "narendra-mulani"
        },
        jay: {
            name: "Jay Vijayan",
            position: "Founder & CEO, Tekion Corp",
            quote: `India needs all the help it can get and many I know are impacted.
            This is the time for all of us who can give, to give generously.`,
            imgSrc: require('../../assests/images/covid-19/donors/jay-vijayan.jpg'),
            alt: "jay-vijayan"
        }
    }
    return (
        <>
            <section id="donorTestimonials" className="row justify-content-center bg-light container-fluid margin-left-0 mr-0 pl-0 pr-0  paddingBottom-64-m paddingTop-100-m">
                <div className="col-12">
                    <h2 className="text-center">
                        <span className="mb-5">Donor Testimonials</span>
                    </h2>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 p-4">
                    <div className="row mb-2">

                        <DonorTeamMember props={donorData.reshma} />

                        <DonorTeamMember props={donorData.kamini} />

                        {/* <div class="col teams-member">
                            <img class="mx-auto d-block" src={require('../assests/images/covid-19/donors/reshma-kewalramani.jpg')} />
                            <div class="founder mb-4">
                                <div> <span><b>Reshma Kewalramani</b></span> </div>
                                <p class="mb-1">CEO and President at Vertex</p>
                                <p class="founder-quote">I am heartbroken by the deepening COVID crisis in India. Urgent action is needed to
                                    help those suffering. Please join me in giving as generously as you can to support India in her time of need.</p>
                            </div>
                        </div> */}
                        {/* <div class="col teams-member">
                            <img class="mx-auto d-block" src={require('../assests/images/covid-19/donors/kamini-ramani.jpg')} />
                            <div class="founder mb-4">
                                <div> <span><b>Kamini Ramani</b></span> </div>
                                <p class="mb-1">Vice President Marketing, Mayfield</p>
                                <p class="founder-quote">The enormity of what we hear from India feels unbearable.
                                    I hope each of us can find some solace in acting to offer financial support to these greatly affected communities.</p>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 p-4">
                    <div className="row mb-2">

                        <DonorTeamMember props={donorData.narendra} />

                        <DonorTeamMember props={donorData.jay} />

                        {/* <div class="col teams-member">
                            <img class="mx-auto d-block" src={require('../assests/images/covid-19/donors/narendra-mulani.jpg')} />
                            <div class="founder mb-4">
                                <div> <span><b>Narendra Mulani</b></span> </div>
                                <p class="mb-1">Investor and Entrepreneur</p>
                                <p class="founder-quote">The need is critical and the time is now. Let's seize the opportunity
                                    to prove ourselves as high-achievers in collective giving. Let us take pride in rising to the
                                    occasion and translate our compassion into generous action through ChaloGive.</p>
                            </div>
                        </div>
                        <div class="col teams-member">
                            <img class="mx-auto d-block" src={require('../assests/images/covid-19/donors/jay-vijayan.jpg')} />
                            <div class="founder mb-4">
                                <div> <span><b>Jay Vijayan</b></span> </div>
                                <p class="mb-1">Founder & CEO, Tekion Corp</p>
                                <p class="founder-quote">India needs all the help it can get and many I know are impacted.
                                    This is the time for all of us who can give, to give generously.</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default DonorTestimonials;