import React, { ReactElement } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/admin.css';
import '../styles/chart.css';
import '../styles/equal-height-columns.css';
import '../styles/newone.css';
import '../styles/stylesheet.css';

function SupportIndia(): ReactElement {
    return (
        <>
            <section id="supportIndia" className="row justify-content-center bg-light container-fluid margin-left-0 mr-0 pl-0 pr-0 paddingBottom-64-m paddingTop-100-m">
                <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                    <h2 className="text-center smaller">
                        <span className="mb-3">Support India in one of three ways:</span>
                    </h2>
                </div>
                <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                    <div className="row paddingBottom-64-m scrollClass">
                        <div className="text-center col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                            <div className="col-12 pt-5 pb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="d-inline text-center smaller">
                                            <span className="text-right text-success number-font-size">1&nbsp;</span>
                                            <span className="minor-underline">COVID Care Centers</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <img className="text-center img-fluid" src={require('../../assests/images/covid-19/logo-wish-foundation.png')} alt="logo-wish-foundation" />
                            <div className="row mt-3">
                                <div className="col-12 text-center">
                                    <p className="font-weight-semi-bold">Isolating COVID patients in COVID Care Centers is the most effective way to control the pandemic and prevent transmission, morbidity and mortality.</p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="text-center col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 offset-sm-2 offset-md-2 offset-lg-2 offset-xl-2 offset-0">
                            <div className="col-12 pt-5 pb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="d-inline text-center smaller">
                                            <span className="text-right text-success number-font-size">2&nbsp;</span>
                                            <span className="minor-underline">Direct Cash Transfer</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <img className="text-center img-fluid" src={require('../../assests/images/covid-19/logo-give-india.png')} alt="logo-give-india" />
                            <div className="row mt-3">
                                <div className="col-12 text-center">
                                    <p className="font-weight-semi-bold">Cash Support for Family of Deceased</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="co-12 text-center  p-4">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="d-inline text-center mb-3 smaller">
                                    <span className="text-right text-success number-font-size">3&nbsp;</span>
                                    <span className="minor-underline">Food Relief and Livelihood Assistance</span>
                                </h2>
                            </div>
                        </div>
                        <img className="img-fluid" src={require('../../assests/images/covid-19/logo-edelgive.jpg')} alt="logo-edelgive" />
                        <img className="img-fluid" src={require('../../assests/images/covid-19/logo-goonj.png')} alt="logo-goonj" />
                        <img className="img-fluid" src={require('../../assests/images/covid-19/logo-jan-sahas.png')} alt="logo-jan-sahas" />
                        <div className="row mt-3">
                            <div className="col-12 text-center">
                                <p className="font-weight-semi-bold">Reach the unreached and provide relief and support to the most vulnerable communities</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SupportIndia;