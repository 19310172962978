import React, { ReactElement } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/admin.css';
import '../styles/chart.css';
import '../styles/equal-height-columns.css';
import '../styles/newone.css';
import '../styles/stylesheet.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";



function PressRelease(): ReactElement {
    return (
        <>
            <section id="pressRelease" className="home-cont2 shadow-btm p-5">
                <div className="container-fluid text-center">
                    <h2 className="pb-4 pt-4"><a target="_blank" rel="noopener noreferrer"
                        href="https://www.indiaspora.org/indiaspora-launches-chalogive-platform-to-continue-covid-19-relief-efforts-to-india/"
                        className="anchor-underline home-cont2">Press Release</a></h2>
                    <div className="h4 pt-4">Follow Us</div>
                    <div className="h5 pt-4">#ChaloGiveCOVID19 </div>
                    <div className="soc-links">
                        <a id="main-facebook" target='_blank' rel="noopener  noreferrer" href='https://www.facebook.com/chalogive/'><FontAwesomeIcon className="soc-facebook" icon={faFacebookF} /></a>
                        <a id="main-twitter" target='_blank' rel="noopener  noreferrer" href='https://twitter.com/chalo_give'><FontAwesomeIcon className="soc-twitter" icon={faTwitter} /></a>
                        <a id="main-instagram" target='_blank' rel="noopener  noreferrer" href='https://www.instagram.com/chalogive/'><FontAwesomeIcon className="soc-instagram" icon={faInstagram} /></a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PressRelease;