import React, { ReactElement } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/admin.css';
import '../styles/chart.css';
import '../styles/equal-height-columns.css';
import '../styles/newone.css';
import '../styles/stylesheet.css';
import OurGoals from './OurGoals';

interface OurGoalData {
    [keyName: string]: {
        heading: string;
        para: string;
        imgSrc: string;
        alt: string;
    };
}

function AboutOurGoal(): ReactElement {

    const OurGoalData: OurGoalData = {
        enablers: {
            heading: "Be Enablers",
            para: "Connect donors to great organizations and make giving easy.",
            imgSrc: require('../../assests/images/enablers.png'),
            alt: "img-enablers"
        },
        inclusive: {
            heading: "Be Inclusive",
            para: "No amount is too small. Make giving possible for all budgets.",
            imgSrc: require('../../assests/images/inclusive.png'),
            alt: "img-inclusive"
        },
        strategic: {
            heading: "Be Strategic",
            para: "Give to organizations that can maximize the impact of your giving.",
            imgSrc: require('../../assests/images/strategic.png'),
            alt: "img-strategic"
        },
        transformational: {
            heading: "Be Transformational",
            para: "Change the way people think about philanthropy.",
            imgSrc: require('../../assests/images/transformational.png'),
            alt: "img-transformational"
        }
    }

    return (
        <>
            <div className="row justify-content-center  bg-light  container-fluid margin-left-0 mr-0 pl-0 pr-0 paddingBottom-64-m">
                <div className="col-12 paddingTop-100-m pb-5">
                    <h2 className="text-center">
                        <span className="">Our Goal</span>
                    </h2>
                </div>
                <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 text-center">
                    <div className="row  mb-5  justify-content-center">
                        <div className="col-12 mb-4 mb-sm-0 text-center mb-md-0 mb-lg-0 mb-xl-0 col-sm-5 col-md-5 col-lg-5 col-xl-5 offset-0 offset-sm-0 offset-md-0 offset-lg-1 offset-xl-1">

                            <OurGoals props={OurGoalData.enablers} />

                        </div>
                        <div className="col-12 text-center col-sm-5 col-md-5 col-lg-5 col-xl-5 offset-0 offset-sm-0 offset-md-0 offset-lg-1 offset-xl-1">

                            <OurGoals props={OurGoalData.inclusive} />

                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-12 mb-4 mb-sm-0  mb-md-0 mb-lg-0 mb-xl-0 col-sm-5 col-md-5 col-lg-5 col-xl-5 offset-0 offset-sm-0 offset-md-0 offset-lg-1 offset-xl-1">

                        <OurGoals props={OurGoalData.strategic} />

                        </div>
                        <div className="col-12  col-sm-5 col-md-5 col-lg-5 col-xl-5 offset-0 offset-sm-0 offset-md-0 offset-lg-1 offset-xl-1">

                        <OurGoals props={OurGoalData.transformational} />
                        
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutOurGoal;