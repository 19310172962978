import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import ChalogiveNavbar from "../components/shared/Navbar/Navbar";
import Cover from "../components/shared/cover/Cover";
import SupportIndia from "../components/supportIndia/SupportIndia";
import Contributors from "../components/contributors/Contributors";
import DonorTestimonials from "../components/donor-testimonial/DonorTestimonials";
import PressRelease from "../components/press-release/PressRelease";
import Footer from "../components/shared/footer/Footer";



function ChaloGiveMain() {
    const imageArray: string[] = [
        require('../assests/images/covid-19/cover2/old-woman-with-mask.jpg'),
        require('../assests/images/covid-19/cover2/covid-india-3.jpg'),
        require('../assests/images/covid-19/cover2/wheelchair.jpeg'),
        require('../assests/images/covid-19/cover2/thermal-checks.jpg'),
        require('../assests/images/covid-19/cover2/oxygen-mask.jpg'),
    ]
    return (
        <>
            <ChalogiveNavbar />

            < Cover imageArray={imageArray} classname="HomepagePhotoSlider-image-home" />

            < SupportIndia />

            < Contributors />

            < DonorTestimonials />

            < PressRelease />

            < Footer />
        </>
    )
}

export default ChaloGiveMain;