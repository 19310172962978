import React, { ReactElement, useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/admin.css';
import '../../styles/chart.css';
import '../../styles/newone.css';
import '../../styles/stylesheet.css';

function ChalogiveNavbar(): ReactElement {
    const [scrollTop, setScrollTop] = useState(0);
    const [navbarStyle, setNavbarStyle] = useState('navbar navbar-expand-lg fixed-top navbar-padding-r-l');
    const [imgStyle, setImgStyle] = useState('img-fluid img-non-shrink');
    const [aboutTextStyle, setAboutTextStyle] = useState('nav-link');

    const handleScroll = () => {
        setScrollTop(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (scrollTop > 0) {
            setNavbarStyle('navbar navbar-expand-lg fixed-top navbar-padding-r-l navbar-bg-gray navbar-shrink');
            setImgStyle('img-fluid img-shrink');
            setAboutTextStyle('nav-link shrink');
        } else {
            setNavbarStyle('navbar navbar-expand-lg fixed-top navbar-padding-r-l');
            setImgStyle('img-fluid img-non-shrink');
            setAboutTextStyle('nav-link');
        }
    }, [scrollTop]);

    return (
        <>
            <Navbar className={navbarStyle}>
                <a className="navbar-brand mr-auto" href="/">
                    <img className={imgStyle} src={require('../../../assests/images/chaloGive/chalogive.png')} alt="Giving Rise" />
                    <span className="indiaspora-initiative"><i> an Indiaspora initiative</i></span>
                </a>
                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item text-center align-self-center" data-match-route="/about">
                            <a className={aboutTextStyle} href="/about">About</a>
                        </li>
                    </ul>
                </div>
            </Navbar>
        </>
    );
}

export default ChalogiveNavbar;