import React, { ReactElement } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/admin.css';
import '../styles/chart.css';
import '../styles/equal-height-columns.css';
import '../styles/newone.css';
import '../styles/stylesheet.css';

interface OurGoalDataProps {
    props: {
        heading: string;
        para: string;
        imgSrc: string;
        alt: string;
    };
}

function OurGoals(props: OurGoalDataProps): ReactElement {

    return (
        <>
            <div className="row align-items-center">
                <div className="col-3 col-sm-4 col-md-3 col-lg-3 col-xl-2 pr-0">
                    <img src={props.props.imgSrc} alt={props.props.alt} />
                </div>
                <div className="col-9 col-sm-8 text-left-our-goal-para col-md-9 col-lg-9 col-xl-10">
                    <h3 className="mb-2 text-dark">{props.props.heading}</h3>
                    <p>{props.props.para}</p>
                </div>
            </div>
        </>
    )
}

export default OurGoals;