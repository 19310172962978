import React, { ReactElement, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/admin.css';
import '../../styles/chart.css';
import '../../styles/equal-height-columns.css';
import '../../styles/newone.css';
import '../../styles/stylesheet.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

interface CoverProps {
    imageArray: string[];
    classname: string;
}

function Cover(props: CoverProps): ReactElement {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [textHeading, setTextHeading] = useState(<div className="position-relative zIndex-7 text-center justify-content-center p-2">
    <div className="marginHorizontal-auto text-center justify-content-center">
        <h1 className="fontSize-48 lineHeight-1d5 lineHeight-1d25-m text-light text-center fontWeight-semibold">STAND WITH INDIA</h1>
        <h1 className="fontSize-48 text-light mt-4"> Give Now. Support India's Fight Against COVID-19.</h1>
        <div className="money-raised h1 text-success mt-5">$ 3,571,430</div>
        <div className="h3 light fontSize-48 text-success mt-xs-1 mt-md-3"> Funds raised</div>
    </div>
</div>);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % props.imageArray.length);
        }, 5000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.classname === "HomepagePhotoSlider-image-about") {
            setTextHeading(<div className="position-relative zIndex-7 text-center justify-content-center">
            <div className="marginHorizontal-auto text-center justify-content-center">
                <h1 className="fontSize-48 lineHeight-1d5 lineHeight-1d25-m text-light mb-2 text-center fontWeight-semibold">We can all be philanthropists</h1>
            </div>
        </div>)
        } else {
            setTextHeading(<div className="position-relative zIndex-7 text-center justify-content-center p-2">
            <div className="marginHorizontal-auto text-center justify-content-center">
                <h1 className="fontSize-48 lineHeight-1d5 lineHeight-1d25-m text-light text-center fontWeight-semibold">STAND WITH INDIA</h1>
                <h1 className="fontSize-48 text-light mt-4"> Give Now. Support India's Fight Against COVID-19.</h1>
                <div className="money-raised h1 text-success mt-5">$ 3,571,430</div>
                <div className="h3 light fontSize-48 text-success mt-xs-1 mt-md-3"> Funds raised</div>
            </div>
        </div>)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <>
            <section id='cover' className="Hero position-relative align-items-center d-flex justify-content-center">
                <div className="zIndex-1">
                    <div id='coverImage' className="backgroundColor-grayMedium position-absolute top-0 left-0 right-0 bottom-0">
                        <div id="coverImageContainer" className={props.classname}
                            style={{ backgroundImage: `url(${props.imageArray[currentIndex]})` }}>
                        </div>
                    </div>
                </div>
                <div className="Hero-overlay position-absolute zIndex-6"></div>
                {
                    textHeading
                }
                <FontAwesomeIcon className='fa fa-2x hero-down-arrow' icon={faAngleDown} />
            </section>
        </>
    )
}

export default Cover;